import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Container, Form, InputGroup } from "react-bootstrap"
import toast from "react-hot-toast";
import moment from "moment"

import * as setup from "models/setup";

import TableTemplate from "components/TableTemplate"
import AddEditSubCategory from "./AddEditSubCategory"
import DeleteSubCategory from "./DeleteSubCategory"

const SubCategory = props => {
  const [subcategories, setSubCategories] = useState([])
  const [category, setCategory] = useState({})


  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [searchText, setSearchText] = useState("")

  //modal
  const [showAddEditSubCategory, setShowAddEditSubCategory] = useState(false)
  const [showdeleteSubCategory, setShowDeleteSubCategory] = useState(false)

  const {
    match: { params },
  } = props

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            {/* <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/category/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link> */}
            <button
              className="btn btn-sm text-primary text-nowrap "
              onClick={() => {
                setShowAddEditSubCategory(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteSubCategory(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Name", selector: row => row?.subCategoryName, sortable: true },
    { name: "Created", selector: row => moment(row?.created).format('LL'), sortable: true }


  ]
  const onFilter = (e) => {
    const searchValue = e.target.value
    setSearchText(searchValue)
  }
  const subHeaderNav = (
    <div className="d-flex justify-content-between w-100">
      <div className="justifycontent-between   align-items-center search-bar-container   rounded-0   ">

        <InputGroup className="rounded rounded-0 bg-white search-bar " >
          <span className="fw-bold input-group-text bg-primary  rounded rounded-0 text-white">
            {/* <i className="fa fa-search" /> */}
            Search
          </span>
          <Form.Control
            value={searchText}
            onChange={onFilter}
            placeholder="Type to search..."
            className="border-1 shadow-none rounded rounded-0 "
            size="md"
          />
          <button
            type="button"
            onClick={() => setSearchText("")}
            className={`btn  rounded rounded-0 text-primary fw-bold py-1 border  border-1 ${searchText.length > 0 ? "" : "d-none"
              }`}
          >
            X
          </button>


        </InputGroup>

      </div>
      <div className="d-none  d-md-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditSubCategory(true) }}>
          Add SubThematic Area
        </button>
      </div>
    </div>

  )
  const getCategoryById = () => {
    setIsLoaded(false)
    setup.getCategoryById(params?.id)
      .then(resp => {

        const data = resp?.category
        setCategory(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })

  }
  const getAllSubCategories = (params) => {
    setIsLoaded(false)
    setup.getAllSubCategories(params)
      .then(resp => {
        const data = resp?.categories
        setSubCategories(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })

  }

  useEffect(() => {
    getAllSubCategories()
    getCategoryById()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <TableTemplate
            dataLoaded={isLoaded}
            rows={subcategories}
            columns={columns}
            subHeaderNav={subHeaderNav}
          />
          <AddEditSubCategory
            showModal={showAddEditSubCategory}
            setShowModal={setShowAddEditSubCategory}
            toEdit={toEdit}
            setToEdit={setToEdit}
            selected={selected}
            setSelected={setSelected}
            fetchData={getAllSubCategories}
            category={category} />

          <DeleteSubCategory
            showModal={showdeleteSubCategory}
            setShowModal={setShowDeleteSubCategory}
            selected={selected}
            setSelected={setSelected}
            fetchData={getAllSubCategories}
          />
        </Card>
      </Container>
    </div>
  )
}

SubCategory.propTypes = {}

export default SubCategory