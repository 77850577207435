import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import Select from "react-select";

import toast from "react-hot-toast";

import * as file from "models/files";
import * as setup from "models/setup";

const AddEditFile = props => {
  const [isLoading, setIsLoading] = useState(false)

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [counties, setCounties] = useState([])
  const [selectedFileType, setSelectedFileType] = useState({ name: "pdf", value: "pdf" })
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)



  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    setSelectedFileType({ name: "pdf", value: "pdf" })
    setSelectedCategory(null)
    setSelectedFile(null)
    props.setSelected(null)
  }
  const getAllSubCategories = (params) => {
    setup.getAllSubCategories(`categoryName=${params}`)
      .then(resp => {
        const data = resp?.subCategories
        setSubCategories([
          {
            label: "Sub Categories",
            options: data.map(item => ({
              label: item?.subCategoryName,
              value: item?.subCategoryName
            }))
          }
        ])
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })

  }
  const handleSelectedFileType = () => {

  }

  const handleSelectedCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory)
    getAllSubCategories(selectedCategory?.label)
  }
  const handleSelectedSubCategory = (selectedSubCategory) => {
    setSelectedSubCategory(selectedSubCategory)

  }

  const handleSelectedCounty = (selectedCounty) => {
    setSelectedCounty(selectedCounty)
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    console.log(file)
    setSelectedFile(file)
  }

  const uploadFile = (fileId, token) => {
    const formData = new FormData()
    formData.append("file", selectedFile)
    file.uploadFile(formData, fileId, token)
      .then(resp => {
        setIsLoading(false)
        closeModal()
      })
      .catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)

    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    const form = event.currentTarget
    const payload = {
      title: form.title.value,
      description: form.description.value,
      category: selectedCategory.value,
      subCategory: selectedSubCategory.value,
      fileType: selectedFileType?.value,
      county: selectedCounty?.value,
      ownerTitle: profile?.userTitle,
      ownerEmail: profile?.userEmail,
    }

    if (props.toEdit) {
      file.updateFile(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          console.log(data)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })
    } else {
      file.addFiles(payload, token)
        .then(resp => {
          const data = resp.data
          console.log(resp)

          uploadFile(data?._id, token)
          //closeModal(true)
        }).catch(err => {
          console.log(err)
          setIsLoading(false)
          toast.error(err?.response?.data?.message)
        })

    }
  }

  useEffect(() => {
    props.selected?.fileType === "pdf" ? setSelectedFileType({
      label: "Pdf",
      value: "pdf",
    }) : props.selected?.fileType === "audio" ? setSelectedFileType({
      label: "Audio",
      value: "audio",
    }) : props.selected?.fileType === "video  " ? setSelectedFileType({
      label: "Video",
      value: "video",
    }) : null
  }, [props.selected])

  const getAllCategories = () => {
    setup.getAllCategories("")
      .then(resp => {
        const data = resp?.categories
        setCategories([
          {
            label: "categories",
            options: data.map(item => ({
              label: item?.categoryName,
              value: item?.categoryName
            }))
          }
        ])
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  const getAllCounties = () => {
    setup.getAllCounties()
      .then(resp => {
        const data = resp?.counties
        setCounties([
          {
            label: "Counties",
            options: data.map(item => ({
              label: item?.countyName,
              value: item?.countyName
            }))
          }
        ])
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })

  }

  useEffect(() => {
    setSelectedCounty({ label: props.selected?.county, value: props.selected?.county })

    setSelectedCategory({ label: props.selected?.category, value: props.selected?.category })


  }, [props.selected])

  useEffect(() => {
    getAllCategories()
    getAllCounties()
  }, [])
  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} File
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {props.toEdit === false && (
              <Form.Group className="mb-3">
                <Form.Label>
                  Select File Type
                </Form.Label>
                <div className="d-flex" >
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      type="radio"
                      defaultChecked
                      name="flexRadioDisabled"
                      value={"pdf"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Pdf
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      value={"audio"}
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Audio
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      type="radio"
                      value={"video"}
                      name="flexRadioDisabled" id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Video
                    </label>
                  </div>
                </div>
              </Form.Group>
            )}
            {props.toEdit === false && (
              <Form.Group className="mb-3" controlId="">
                <Form.Label>
                  Upload File
                </Form.Label>
                <div className="d-flex align-items-center">
                  <input
                    name="file"
                    id="file"
                    className="form-control"
                    type="file"
                    accept={selectedFileType.value === "audio" ? '.mp3' : selectedFileType.value === "pdf" ? '.pdf' : selectedFileType.value === "video" ? '.mp4' : null}

                    onChange={handleFileChange}
                    required
                  />
                </div>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="title">
              <Form.Label>
                Title
              </Form.Label>
              <Form.Control
                placeholder="Enter File Title"
                defaultValue={props.selected?.title}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>
                Description
              </Form.Label>
              <Form.Control
                placeholder="Write a simple description"
                required
                defaultValue={props.selected?.description}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="category">
              <Form.Label>
                Thematic Area
              </Form.Label>
              <Select
                value={selectedCategory}
                onChange={handleSelectedCategory}
                options={categories}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label>
                Sub Thematic Area
              </Form.Label>
              <Select
                value={selectedSubCategory}
                onChange={handleSelectedSubCategory}
                options={subCategories}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                County
              </Form.Label>
              <Select
                value={selectedCounty}
                onChange={handleSelectedCounty}
                options={counties}
              />
            </Form.Group>

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditFile.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  toEdit: PropTypes.bool,
  setToEdit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func
}


export default AddEditFile