import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Categories
//post functions
export const addCategory = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_CATEGORY, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllCategories = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_CATEGORIES}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getCategoryById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET_CATEGORY_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateCategory = async (payload, token, categoryId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_CATEGORY}/${categoryId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteCategory = async (categoryId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_CATEGORY}/${categoryId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//SubCategories
//post functions
export const addSubCategory = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_SUB_CATEGORY, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllSubCategories = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_SUB_CATEGORIES}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getSubCategoryById = async (id) => {
  return await axios
    .get(getApi.SETUP.GET_SUB_CATEGORY_BY_ID(id))
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateSubCategory = async (payload, token, subCategoryId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_SUB_CATEGORY}/${subCategoryId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteSubCategory = async (subCategoryId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_SUB_CATEGORY}/${subCategoryId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}


//Conties
//post functions
export const addCounty = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_COUNTY, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllCounties = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_COUNTIES}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getCountyById = async (id) => {
  return await axios
    .get(getApi.SETUP.GET_COUNTY_BY_ID(id))
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateCounty = async (payload, token, countyId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_COUNTY}/${countyId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteCounty = async (countyId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_COUNTY}/${countyId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}