import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useState } from "react"
import { Card } from "react-bootstrap"
import toast from "react-hot-toast";
import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import * as log from "models/logs";

import TableTemplate from "components/TableTemplate"
import ViewLog from "./ViewLog";

const Logs = props => {
  const [logs, setLogs] = useState([])
  const [selected, setSelected] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)

  const [showModalLog, setShowModalLog] = useState(false)

  const columns = [
    {
      name: "",

      cell: (row, index) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-primary text-nowrap "
              onClick={() => {
                setShowModalLog(true)
                setSelected(row)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                {index + 1}
              </p>
            </button>




          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '1px'
    },
    {
      name: "",

      cell: (row, index) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-primary text-nowrap "
              onClick={() => {
                setShowModalLog(true)
                setSelected(row)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </button>




          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px'
    },
    { name: "Action", selector: row => row.action },
    { name: "Item Id", selector: row => row.actionItem },

    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div>

    </div>
  )

  const getLogs = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    log.getAllLogs(params, token)
      .then(resp => {
        const data = resp.logs
        console.log(resp)
        setLogs(data)
        setIsLoaded(true)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }

  const getLogsByParams = () => {

  }
  useEffect(() => {
    getLogsByParams()
  }, [])
  useEffect(() => {
    getLogs()
  }, [])
  return (
    <div className="page-content">
      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("Logs")}
        breadcrumbItem={("Logs")}
      />
      <Card>
        <Card.Body>
          <TableTemplate
            dataLoaded={isLoaded}
            rows={logs}
            columns={columns}
          />
        </Card.Body>
      </Card>

      <ViewLog
        showModal={showModalLog}
        setShowModal={setShowModalLog}
        setSelected={setSelected}
        selected={selected} />
    </div>
  )
}

localStorage.propTypes = {}

export default Logs