import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import UserManagement from "pages/UserManagement"
import Logs from "pages/Logs"

import Setup from "pages/Setup"
import Analytics from "pages/Analytics"
import ViewUser from "pages/UserManagement/ViewUser"
import ContentManagement from "pages/ContentManagement"
import SubCategory from "pages/Setup/SubCategory"
import ForgetPasswordUrl from "pages/Authentication/ForgetPasswordUrl"



const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/user-management", component: UserManagement },
  { path: "/content-management", component: ContentManagement },
  { path: "/user-profile/:id", component: ViewUser },
  { path: "/logs", component: Logs },
  { path: "/setup", component: Setup },
  { path: "/analytics", component: Analytics },
  { path: "/category/:id", component: SubCategory },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/set-password/:token", component: ForgetPasswordUrl },
]

export { authProtectedRoutes, publicRoutes }
