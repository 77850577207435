import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

export const getAllLogs = async (params, token) => {
  return await axios
    .get(`${getApi.LOGS.GET_ALL_LOGS}?${params === undefined ? "" : params}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError(err, err));
}

export const getLogById = async (logId, token) => {
  return await axios
    .get(`${getApi.LOGS.GET_LOG_BY_ID}/${logId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError(err, err));
}