import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
} from "reactstrap";

import * as analytics from "models/analytics";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import ScoreCard from "./ScoreCard";
import Overview from "./Overview";


const Dashboard = props => {
  const [analyticData, setAnalyticData] = useState({})
  //meta title
  document.title = "Dashboard | KARA";

  const getAnalytics = (params) => {

    analytics.getAllAnalytics(params)
      .then(resp => {
        setAnalyticData(resp.analytics)
      })
      .catch(err => {

      })
  }

  // useEffect(() => {
  //   getAnalytics()
  // }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <ScoreCard />
          <Overview departments={[""]} dataColors='["--bs-primary", "--bs-secondary" ]' />
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
