import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select";

import * as auth from "models/auth";
const AddEditUser = props => {
  const [isLoading, setIsLoading] = useState(false)

  const [selectedUserType, setSelecteduserType] = useState()

  const userTypes = [
    {
      label: "User Types", options: [
        {
          label: "Super Admin",
          value: "super-admin",
        },
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: "County-Admin ",
          value: "admin-county",
        },

        {
          label: "User",
          value: "user",
        },


      ]
    }

  ]

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    props.setSelected(null)
  }

  const handleSelectedUserType = (selectedUserType) => {
    setSelecteduserType(selectedUserType)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      fullName: form.fullName.value,
      userEmail: form.userEmail.value,
      phoneNumber: form.phoneNumber.value,
      jobTitle: form.jobTitle.value,
      location: form.location.value,
      userType: selectedUserType?.value,
    }
    if (props.toEdit) {
      auth.updateProfile(props.selected?._id, payload, token)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      auth.registerAsAdmin(payload, token)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  useEffect(() => {
    props.selected?.userType === "user" ? setSelecteduserType({
      label: "User",
      value: "user",
    }) : props.selected?.userType === "admin" ? setSelecteduserType({
      label: "Admin",
      value: "admin",
    }) : props.selected?.userType === "superadmin" ? setSelecteduserType({
      label: "Super Admin",
      value: "superadmin",
    }) : setSelecteduserType({
      label: "User",
      value: "user",
    })
  }, [props.selected])
  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} User
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label>
                Full Name
              </Form.Label>
              <Form.Control
                placeholder="e.g John Doe"
                defaultValue={props.selected?.fullName}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="userEmail">
              <Form.Label>
                User Email
              </Form.Label>
              <Form.Control
                placeholder="e.g johndoe@gmail.com"
                required
                defaultValue={props.selected?.userEmail}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phoneNumber">
              <Form.Label>
                Phone Number
              </Form.Label>
              <Form.Control
                placeholder="e.g 0712345678"
                required
                defaultValue={props.selected?.phoneNumber}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="jobTitle">
              <Form.Label>
                Job Title
              </Form.Label>
              <Form.Control
                placeholder="e.g CTO"
                required
                defaultValue={props.selected?.jobTitle}

              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="location">
              <Form.Label>
                Location
              </Form.Label>
              <Form.Control
                placeholder="e.g Kisumu"
                required
                defaultValue={props.selected?.location}

              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                User Type
              </Form.Label>
              <Select
                value={selectedUserType}
                onChange={handleSelectedUserType}
                options={userTypes}
              />
            </Form.Group>

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditUser.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  toEdit: PropTypes.bool,
  setToEdit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func
}

export default AddEditUser