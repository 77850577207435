import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from "react-bootstrap"
import Categories from "./Categories/Categories"
import Counties from "./Counties/Counties"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


const Setup = props => {
  const [userProfile, setUserProfile] = useState({})

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    setUserProfile(profile)
  }, [])
  return (
    <div className="page-content">

      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("SETUP")}
        breadcrumbItem={("SETUP")}
      />

      <Categories />
      {userProfile?.userType === "super-admin" || userProfile?.userType === "admin" && (<Counties />)}



    </div>
  )
}

Setup.propTypes = {}

export default Setup